@config "../../tailwind.landing.config.ts";

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
  background-color: #fff;
  overflow-x: hidden;
}

body {
  min-height: 100vh;
  width: 100%;
  height: 100%;
  overflow-x: clip;
}

@layer base {
  :root {
    --sph-color-brand: 224 94% 57%; /* #2c61f9 */
    --sph-color-brand-variant: 191 100% 58%;
    --sph-color-inverse: 0 0% 100%; /* #ffffff */
    --sph-color-on-color: 0 0% 100%; /* #ffffff */

    --sph-color-text: 220 43% 11%; /* #101828 */
    --sph-color-text-variant: 209 63% 16%; /* #0F2A43 */
    --sph-color-text-subtle: 216 21% 55%; /* #7387a4 */
    --sph-color-text-brand: var(--sph-color-brand);
    --sph-color-text-on-surface-dark: 220 16% 59%; /* #8792A8 */

    --sph-color-background: 0 0% 100%; /* #ffffff */
    --sph-color-background-hover: 210 25% 97%; /* #f5f7f9 */
    --sph-color-background-dim: 218 57% 97%; /* #F4F7FC */
    --sph-color-background-dim-hover: 218 25% 95%; /* #EDF0F5 */
    --sph-color-background-dark: 238 51% 11%; /* #0E0F2B */

    --sph-color-bg-accent-blue-200: 214 100% 97%;

    --sph-color-bg-surface-1: 0 0% 100%; /* #ffffff */
    --sph-color-bg-surface-1-hover: 210 25% 97%; /* #f5f7f9 */
    --sph-color-bg-surface-2: 210 25% 97%; /* #f5f7f9 */
    --sph-color-bg-surface-2-hover: 0 0% 100%; /* #ffffff */
    --sph-color-bg-surface-3: 218 57% 97%; /* #F4F7FC */
    --sph-color-bg-surface-3-hover: 218 57% 99%;

    --sph-color-bg-surface-dark: 238 51% 11%; /* #0E0F2B */

    --sph-color-outline: 224 100% 95%; /* E4EBFF */

    --sph-color-bg-button-primary: linear-gradient(
      180deg,
      #3b6eff 0.02%,
      #164be2 100.05%
    );

    --sph-color-bg-button-secondary: linear-gradient(
      180deg,
      #ffffff 71.06%,
      #f6f8ff 100.05%
    );
    --sph-color-bg-button-secondary-hover: var(--sph-color-bg-surface-1-hover);
    --sph-color-border-button-secondary: 224 100% 95%; /* #E8EEFF */

    --sph-color-bg-button-secondary-dark: 225 41% 23%; /* #222E52 */
    --sph-color-bg-button-secondary-dark-hover: 225 41% 27%;
    --sph-color-border-button-secondary-dark: 225 36% 45%; /* #4A5F9D */
    --sph-color-icon-button-secondary-dark: 224 100% 95%; /* #E4EBFF */

    --sph-color-vertical-ruler: 224 100% 95%; /* #E4EBFF */
    --sph-color-divider: 224 100% 90%; /* #CAD8FF */

    --header-height: 4.75rem; /* 76px */
    --page-width: 1248px;
    --page-margin: max(calc(calc(100vw - var(--page-width)) / 2), 2rem);
    --max-w-screen-lp: var(--page-width);
  }

  html {
    scroll-behavior: smooth;
  }
  main {
    @apply mx-auto h-full w-full max-w-screen-xl;
  }
}

@layer utilities {
  .x-padding-page {
    @apply px-4 md:px-8;
  }

  .shadow-filter {
    -webkit-filter: drop-shadow(0px 30.16247px 39.03379px rgba(0, 0, 0, 0.1));
    filter: drop-shadow(0px 30.16247px 39.03379px rgba(0, 0, 0, 0.1));
  }

  .w-screen-center {
    @apply left-[calc(-50vw_+_50%)] w-screen;
  }

  .scrollbar-hide {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  @keyframes marquee-scroll {
    to {
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes hide-scroll {
    from,
    to {
      overflow: hidden;
    }
  }

  .hide-scroll-on-animation {
    --hide-scroll-on-animation-duration: 1s;
    animation: hide-scroll var(--hide-scroll-on-animation-duration) backwards;
  }

  @keyframes float {
    from {
      transform: translate3d(0, var(--animate-float-from), 0);
    }

    to {
      transform: translate3d(0, var(--animate-float-to), 0);
    }
  }

  .animate-float {
    --animate-float-from: -5px;
    --animate-float-to: 6px;
    --animate-float-duration: 3s;
    animation-name: float;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: var(--animate-float-duration);
    animation-direction: alternate;
  }

  @keyframes ticker {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(-100%, 0, 0);
    }
  }

  .animate-ticker {
    --animate-ticker-duration: 20s;
    animation-name: ticker;
    animation-duration: var(--animate-ticker-duration);
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  @keyframes morph {
    0%,
    100% {
      border-radius: 25% 40% 50% 40%;
      transform-origin: center;
      transform: translate(-50%, -50%) scale(1);
    }
    12.5% {
      border-radius: 40% 50% 40% 50%;
      transform: translate(-50%, -50%) scale(1.1);
    }
    25% {
      border-radius: 50% 40% 30% 40%;
      transform: translate(-50%, -50%) scale(1.2);
    }
    37.5% {
      border-radius: 40% 30% 50% 25%;
      transform: translate(-50%, -50%) scale(1.1);
    }
    50% {
      border-radius: 20% 50% 40% 50%;
      transform: translate(-50%, -50%) scale(1.125);
    }
    62.5% {
      border-radius: 50% 40% 50% 40%;
      transform: translate(-50%, -50%) scale(1.1);
    }
    75% {
      border-radius: 40% 50% 30% 50%;
      transform: translate(-50%, -50%) scale(1.05);
    }
    87.5% {
      border-radius: 50% 30% 40% 30%;
      transform: translate(-50%, -50%) scale(1.1);
    }
  }

  @keyframes colorChange {
    0%,
    100% {
      background-color: #2c92f9;
    }
    25% {
      background-color: #4eb8ff;
    }
    50% {
      background-color: #2c81f9;
    }
    75% {
      background-color: #2c61f9;
    }
  }

  .animate-morph {
    left: 50%;
    top: 50%;
    transform-origin: center;
    transform: translate(-50%, -50%) scale(1);
    animation: morph 20s infinite;
  }
}
